import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import footerStyle from './footer.module.scss';

class Footer extends Component {
  render() {
    return (
      <footer>
          <div className={footerStyle.footerInner}>
            <div className={footerStyle.leftPanel}>
              <span>Navigation</span>
              <div className={footerStyle.menu}>
                <Link aria-label="about" to="/About">About</Link>
                <Link aria-label="portfolio" to="/Portfolio">Portfolio</Link>
                <Link aria-label="contact" to="/Contact">Contact</Link>
                <Link aria-label="cv" to="/CV">CV</Link>
                {/* <Link aria-label="Blog" to="/Blog">Blog</Link> */}
                <Link target="_blank" rel="noreferrer" aria-label="linkedin" to="//www.linkedin.com/in/danjbennett/">Linkedin</Link>
              </div>
            </div>
            <div className={footerStyle.rightPanel}>
              <div className={footerStyle.menuRight}>
                <Link target="_blank" rel="noreferrer" aria-label="linkedin" to="//www.linkedin.com/in/danjbennett/"><FontAwesomeIcon icon={['fab', 'linkedin']}></FontAwesomeIcon></Link>
                <Link target="_blank" rel="noreferrer" aria-label="facebook-messenger" to="//m.me/dannersjb"><FontAwesomeIcon icon={['fab', 'facebook-messenger']}></FontAwesomeIcon></Link>
                <a href="mailto:dannersjb@gmail.com" aria-label="email dan"><FontAwesomeIcon icon={['fas', 'envelope']}></FontAwesomeIcon></a>
              </div>
              <p>Daniel Bennett © {(new Date().getFullYear())}</p>
            </div>
          </div>
      </footer>
    );
  }
}

export default Footer;