import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Testimonial from './testimonial'

class Testimonials extends Component {
    state = {
        list: [
            {
                name: "Irina",
                company: "Dinamic Cleaning Services",
                projectLink: "/Portfolio/Dinamicservices",
                testimonial: "I cannot begin to thank Daniel for the amazing work he did on my business site! I could have never imagined it would have looked so incredible and functioned so well! I’d recommend him to ANYONE looking to build a website for their business! Thank you Daniel"     
            },
            {
                name: "Nicholas B",
                company: "Focus Print",
                projectLink: "/Portfolio/Focusprint",
                testimonial: "Dan translates my very nontechnical 'can this thing do that'-type requests into a smooth, functional custom website which I'm super happy with. Thanks, Dan!."   
            }
        ]
    }

  render() {
    const NextButton = ({currentSlide, slideCount, ...props}) => ( <FontAwesomeIcon {...props} icon={['fas', 'chevron-right']}></FontAwesomeIcon> )
    const PrevButton = ({currentSlide, slideCount, ...props}) => ( <FontAwesomeIcon {...props} icon={['fas', 'chevron-left']}></FontAwesomeIcon> )
    const settings = {
        dots: false,
        infinite: true,
        nextArrow: <NextButton></NextButton>,
        prevArrow: <PrevButton></PrevButton>,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <div className="page">
            <h2>Project Feedback</h2>
            <Slider {...settings}>
                {
                    this.state.loading || !this.state.list ? (
                        null
                    ) : (
                        this.state.list.map((item, index) => 
                            <Testimonial item={item} key={index}/>
                        ) 
                    )
                }
            </Slider>
        </div>
    );
  }
}

export default Testimonials;
