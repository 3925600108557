import React from 'react';
import { Outlet } from "react-router-dom";
import Header from './components/base/header';
import Footer from './components/base/footer';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight, faBars, faTerminal, faDatabase, faExclamationTriangle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faYoutubeSquare, faReact, faPhp, faJsSquare, faJs, faAngular, faHtml5, faCss3, faNodeJs, faSass, faJava, faGit, faLinux, faFacebookMessenger, faLinkedin, faGoogle, faMicrosoft, faAws } from '@fortawesome/free-brands-svg-icons';

import './main.scss';

library.add( faChevronLeft, faChevronRight, faBars, faTerminal, faDatabase, faFacebookSquare, faYoutubeSquare, faReact, faPhp, faJsSquare, faJs, faAngular, faHtml5, faCss3, faNodeJs, faSass, faJava, faGit, faLinux, faEnvelope, faExclamationTriangle, faFacebookMessenger, faLinkedin, faGoogle, faMicrosoft, faAws )

export default function Root() {
  return (
      <>
        <Header/>
        <Outlet />
        <Footer/>
      </>
    );
  }

