import React from 'react';
import { Link } from "react-router-dom";

import blogStyle from './blog.module.scss';

export default class BlogPost extends React.Component {
    state = {
        item: null,
        loading: true
    };

    async componentDidMount() {
        this.setState({ item: this.props.item, loading: false});
    }

    render() {
        return (
            <>   
            {
                this.state.loading || !this.state.item ? (
                    <></>
                ) : (
                    <div className={blogStyle.blogPost}>    
                        <Link aria-label={this.state.item.Title} to={this.state.item.LinkName}>    
                            <div>
                                <div className={blogStyle.text}>
                                    <h2>{this.state.item.Title}</h2>
                                    <p>{this.state.item.Date}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    
                )
            }
            </>  
        );
    } 
}
