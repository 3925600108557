import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import projectStyle from './portfolio.css'
import dinamicservicesStyle from './dinamicservices.module.scss'

import desktop from '../../images/dinamicservices_desktop.jpg';
// import tablet from '../../images/focusprint_tablet.png';
import mobile from '../../images/dinamicservices_mobile.jpg';

class Dinamicservices extends Component {
  render() {
    return (
        <div>
          <Helmet>
              <meta charSet="utf-8"/>
              <meta name="Description" content="I'm Dan, I develop websites in various technologies in my spare time. Check out some of my projects and get in touch if you would like a website developed."/>
              <meta name="viewport" content="width=device-width, initial-scale=1"/>
              <link rel="canonical" href="https://www.danjbennett.com/Portfolio/Dinamicservices" />
              <title>Daniel Bennett - Dinamic Services</title>
          </Helmet>
          <div className={projectStyle.slantContainer}>
            <div className={projectStyle.slant}>
              <div className="page">
                <h1 className={projectStyle.title}>Dinamic Services</h1>
                <div className={projectStyle.technologies}>
                  <span className={dinamicservicesStyle.angular}><FontAwesomeIcon icon={['fab', 'react']}></FontAwesomeIcon></span>
                  <span className={dinamicservicesStyle.html}><FontAwesomeIcon icon={['fab', 'html5']}></FontAwesomeIcon></span>
                  <span className={dinamicservicesStyle.css}><FontAwesomeIcon icon={['fab', 'css3']}></FontAwesomeIcon></span>
                  <span className={dinamicservicesStyle.css}><FontAwesomeIcon icon={['fab', 'sass']}></FontAwesomeIcon></span>
                </div>
                <p className={projectStyle.intro}>This is a website I built for a local cleaning service. This website is written in React JS and uses a third party CMS for content management.</p>
                <Link className={projectStyle.button} aria-label="Visit Website - dinamic services" rel="noreferrer" target="_blank" to="//dinamicservices.dannersjb.co.uk">Visit Website</Link>
              </div>
            </div>
          </div>
          <div className="pageOF">
            <div className={projectStyle.devices}>
            <div className={projectStyle.desktop}><img src={desktop} alt="desktop" /></div>
            {/* <div className={projectStyle.tablet}><img src={tablet} alt="tablet"/></div> */}
            <div className={projectStyle.mobile}><img src={mobile} alt="mobile"/></div> 
          </div>
        </div>
      </div>
    );
  }
}

export default Dinamicservices;