import Root from './root'
import HomePage from './components/Home/homePage';
import Portfolio from './components/portfolio/portfolio';
import About from './components/about/about';
import Contact from './components/contact/contact';
import CV from './components/cv/cv';
import BlogList from './components/blog/blogList';
import BlogPage, { loader as blogPageLoader } from './components/blog/blogPage'
import ErrorPage from './components/base/errorPage'
import Obsessiondj from './components/portfolio/obsessiondj'
import Nicholasburman from './components/portfolio/nicholasburman'
import Consortsw1 from './components/portfolio/consortsw1';
import Ecotecture from './components/portfolio/ecotecture';
import Focusprint from './components/portfolio/focusprint';
import Bridiefayeart from './components/portfolio/bridiefayeart';
import Dinamicservices from './components/portfolio/dinamicservices';


import * as React from "react";
import * as ReactDOM from "react-dom";
import * as serviceWorker from './serviceWorker';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
        {
            path: "/",
            element: <HomePage/>
        },
        {
            path: "portfolio",
            element: <Portfolio />,
        },
        {
            path: "about",
            element: <About />,
        },
        {
            path: "contact",
            element: <Contact />,
        },
        {
            path: "cv",
            element: <CV />,
        },
        {
            path: "blog",
            element: <BlogList />,
        },
        {
            path: "blog/:blogPostName",
            element: <BlogPage />,
            loader: blogPageLoader
        },
        {
            path: "portfolio/consortsw1",
            element: <Consortsw1 />,
        },
        {
            path: "portfolio/obsessiondj",
            element: <Obsessiondj />,
        },
        {
            path: "portfolio/nicholasburman",
            element: <Nicholasburman />,
        },
        {
            path: "portfolio/ecotecture",
            element: <Ecotecture />,
        },
        {
            path: "portfolio/focusprint",
            element: <Focusprint />,
        },
        {
            path: "portfolio/dinamicservices",
            element: <Dinamicservices />,
        },
        {
            path: "portfolio/bridiefayeart",
            element: <Bridiefayeart />,
        },
    ]
  },
]);

ReactDOM.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>, 
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
