import React from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import testimonialStyle from './testimonials.module.scss';

export default class testimonial extends React.Component {
    state = {
        item: null,
        loading: true
    };

    async componentDidMount() {
        this.setState({ item: this.props.item, loading: false});
    }

    render() {
        return (
            <div key={this.props.key}>
            {
                this.state.loading || !this.state.item ? (
                    <div>
                        <FontAwesomeIcon icon={['fas', 'spinner']} pulse></FontAwesomeIcon>
                    </div>
                ) : (
                    <div className={testimonialStyle.testimonial}>
                        <div className={testimonialStyle.speechBubble}>
                            <p>"{this.state.item.testimonial}"</p>
                        </div>
                        <span>{this.state.item.name}<br></br><Link aria-label={this.state.item.company} to={this.state.item.projectLink}>{this.state.item.company}</Link></span>
                    </div>
                )
            }
            </div>
        );
    } 
}
