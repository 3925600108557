import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import ContactForm from './contactForm'

import contactStyle from './contact.module.scss';

class Contact extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8"/>
          <meta name="Description" content="Please get in touch if you want help with a website."/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <link rel="canonical" href="https://www.danjbennett.com/Contact" />
          <title>Daniel Bennett - Contact</title>
        </Helmet>
        <div className={contactStyle.slantContainer}>
          <div className={contactStyle.slant}>
            <div className="page">
              <h1 className={contactStyle.title}>Contact</h1>
              <p>Please get in touch with the form below if you want help with a website.</p>
              <ContactForm></ContactForm>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
