import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import { Link } from "react-router-dom";

import Testimonials from './testimonials';

import homeStyle from './homePage.module.scss';

class HomePage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8"/>
          <meta name="Description" content="I'm Dan, I develop websites in various technologies in my spare time. Check out some of my projects and get in touch if you would like a website developed."/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <link rel="canonical" href="https://www.danjbennett.com/" />
          <title>Daniel Bennett - Web Developer</title>
        </Helmet>
        <div className={homeStyle.slantContainer}>
          <div className={homeStyle.slant}>
            <div className="page">
              <h1>Freelance Web Developer</h1>
              <p>I'm Dan, I develop websites in various technologies in my spare time.</p>
              <p>Check out some of my projects and get in touch if you would like a website developed.</p>
              <Link className={homeStyle.button} aria-label="View Portfolio" to="/Portfolio">View Portfolio</Link>
              <Link className={homeStyle.button} aria-label="Get in Touch" to="/Contact">Get in Touch</Link>
            </div>
          </div>
        </div>
        <Testimonials/>
      </div>
    );
  }
}

export default HomePage;
