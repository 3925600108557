import React, { Component } from 'react';

import blogStyle from './blog.module.scss'
import {Helmet} from 'react-helmet';

import BlogPost from './blogPost'

class BlogList extends Component {
    state = {
        blogPosts: null,
        loading: true,
        url: window._env_.API_URL + "/api/collections/get/Blog?token=account-8a913764a6ac6293a05335414f3286",
        urlError: false,
        removeHoriScroll: false
    };
    
    async componentDidMount() {
        await fetch(this.state.url).then((response) => {
            if (response.status >= 400 && response.status < 600) {
              throw new Error("Bad response from server");
            }
            return response;
        }).then((returnedResponse) => {
            return returnedResponse.json()
        }).then((data) => {
           this.setState({ blogPosts: data['entries'], loading: false});
        }).catch((error) => {
          this.setState({ urlError: true, loading: false});
          console.log(error)
        });
    }

  render() {
    return (
        <div className={blogStyle.blogPosts}>
            {
               this.state.loading ? (
                    <></>
                ) : ( 
                    this.state.urlError | !this.state.blogPosts ? (
                        <div className="page">
                            <h2>There has been an Error Loading this Content</h2>
                            <p>Please try again later</p>
                        </div>
                    ) : (
                        <>
                            <Helmet>
                                <meta charSet="utf-8"/>
                                <meta name="Description" content=""/>
                                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                                <link rel="canonical" href="https://www.danjbennett.com/blog"/>
                                <title>Daniel Bennett - Blog</title>
                            </Helmet>
                            <div className='page'>
                                {
                                    this.state.blogPosts.map((item, index) => 
                                        <BlogPost item={item} key={index}/>
                                )} 
                            </div>
                        </>
                    )
                )
            }
        </div>
    );
  }
}

export default BlogList;
