import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import projectStyle from './portfolio.css'
import consortsw1Style from './consortsw1.css'

import desktop from '../../images/consortsw1_desktop.png';
import tablet from '../../images/consortsw1_tablet.png';
import mobile from '../../images/consortsw1_mobile.png';

class Consortsw1 extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8"/>
          <meta name="Description" content="I'm Dan, I develop websites in various technologies in my spare time. Check out some of my projects and get in touch if you would like a website developed."/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <link rel="canonical" href="https://www.danjbennett.com/Portfolio/Consortsw1" />
          <title>Daniel Bennett - Consort SW1</title>
        </Helmet>
        <div className={projectStyle.slantContainer}>
          <div className={projectStyle.slant}>
            <div className="page">
              <h1 className={projectStyle.title}>Consort SW1</h1>
              <div className={projectStyle.technologies}>
                <span className={consortsw1Style.react}><FontAwesomeIcon icon={['fab', 'react']}></FontAwesomeIcon></span>
                <span className={consortsw1Style.node}><FontAwesomeIcon icon={['fab', 'node-js']}></FontAwesomeIcon></span>
                <span className={consortsw1Style.html}><FontAwesomeIcon icon={['fab', 'html5']}></FontAwesomeIcon></span>
                <span className={consortsw1Style.css}><FontAwesomeIcon icon={['fab', 'css3']}></FontAwesomeIcon></span>
                <span className={consortsw1Style.api}><FontAwesomeIcon icon={['fab', 'facebook-square']}></FontAwesomeIcon></span>
                <span className={consortsw1Style.api}><FontAwesomeIcon icon={['fab', 'youtube-square']}></FontAwesomeIcon></span>
              </div>
              <p className={projectStyle.intro}>This project was a website for a local choir and was created using mainly React JS. There is also some NodeJS code for the contact form to send an email. This website makes use of the Facebook and YouTube APIs to keep content up-to-date.</p>
              <Link className={projectStyle.button} aria-label="Visit Website - consortsw1" rel="noreferrer" target="_blank" to="//www.consortsw1.com/">Visit Website</Link>
            </div>
          </div>
        </div>
        <div className="pageOF">
          <div className={projectStyle.devices}>
            <div className={projectStyle.desktop}><img src={desktop} alt="desktop" /></div>
            <div className={projectStyle.tablet}><img src={tablet} alt="tablet"/></div>
            <div className={projectStyle.mobile}><img src={mobile} alt="mobile"/></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Consortsw1;