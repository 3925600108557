import { useRouteError } from "react-router-dom";
import React from 'react';
import {Helmet} from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import errorPageStyle from './errorPage.module.scss';

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);
  
    return (
        <div>
        <Helmet>
            <meta charSet="utf-8"/>
            <meta name="Description" content=""/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <title>404 - Not Found</title>
        </Helmet>
        <div className={errorPageStyle.desktop}>
            <div className={errorPageStyle.screenBox}>
                <span>Page Not Found</span>
                <i>{error.statusText || error.message}</i>
                <FontAwesomeIcon icon={['fas', 'exclamation-triangle']}></FontAwesomeIcon>
            </div>
        </div>
    </div>
    );
  }