import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import projectStyle from './portfolio.css'
import obsessiondjStyle from './obsessiondj.css'

import desktop from '../../images/obsessiondj_desktop.png';
// import tablet from '../../images/obsessiondj_tablet.png';
// import mobile from '../../images/obsessiondj_mobile.png';

class Obsessiondj extends Component {
  render() {
    return (
      <div>
        <Helmet>
            <meta charSet="utf-8"/>
            <meta name="Description" content="I'm Dan, I develop websites in various technologies in my spare time. Check out some of my projects and get in touch if you would like a website developed."/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <link rel="canonical" href="https://www.danjbennett.com/Portfolio/Obsessiondj" />
            <title>Daniel Bennett - Obsession DJ</title>
        </Helmet>
        <div className={projectStyle.slantContainer}>
          <div className={projectStyle.slant}>
            <div className="page">
              <h1 className={projectStyle.title}>Obsession DJ</h1>
              <div className={projectStyle.technologies}>
                <span className={obsessiondjStyle.php}><FontAwesomeIcon icon={['fab', 'php']}></FontAwesomeIcon></span>
                <span className={obsessiondjStyle.html}><FontAwesomeIcon icon={['fab', 'html5']}></FontAwesomeIcon></span>
                <span className={obsessiondjStyle.css}><FontAwesomeIcon icon={['fab', 'css3']}></FontAwesomeIcon></span>
                <span className={obsessiondjStyle.css}><FontAwesomeIcon icon={['fab', 'sass']}></FontAwesomeIcon></span>
                <span className={obsessiondjStyle.api}><FontAwesomeIcon icon={['fab', 'facebook-square']}></FontAwesomeIcon></span>
                <span className={obsessiondjStyle.api}><FontAwesomeIcon icon={['fab', 'youtube-square']}></FontAwesomeIcon></span>
              </div>
              <p className={projectStyle.intro}>This project is a website for a local wedding DJ. This website makes use of the Facebook and YouTube APIs to keep content up-to-date alongside a custom PHP CMS (Content Management System).</p>
              <Link onClick={e => e.preventDefault()} className={projectStyle.buttonDisabled} aria-label="In Progress - obsessiondj" rel="noreferrer" target="_blank" to="/404">In Progress</Link>
            </div>
          </div>
        </div>
        <div className="pageOF">
          <div className={projectStyle.devices}> 
            <div className={projectStyle.desktop}><img src={desktop} alt="desktop" /></div>
            {/* <div className={projectStyle.tablet}><img src={tablet} alt="tablet"/></div>
            <div className={projectStyle.mobile}><img src={mobile} alt="mobile"/></div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Obsessiondj;