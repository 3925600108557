import React, { useState, useEffect } from 'react';

import blogStyle from './blog.module.scss';
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

import { useLoaderData } from "react-router-dom";

export async function loader({ params }) {
  const blogName = await params.blogPostName;
  return { blogName };
}

export default function BlogPage() {
    const { blogName } = useLoaderData();
    const [pageText, setPageText] = useState(null);
    const [loading, setLoading] = useState(true);
    const [url] = useState(window._env_.API_URL + "/api/collections/get/Blog/?token=account-8a913764a6ac6293a05335414f3286&filter[LinkName]=" + blogName);
    const [urlError, setUrlError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await fetch(url).then((response) => {
                if (response.status >= 400 && response.status < 600) {
                    throw new Error("Bad response from server");
                }
                return response;
            }).then((returnedResponse) => {
                return returnedResponse.json()
            }).then((data) => {
                setPageText(data['entries'][0]);
                setLoading(false);
            }).catch((error) => {
                setUrlError(true);
                setLoading(false);
                console.log(error)
            });
        }
        fetchData();
    }, [blogName])

  return(
    <div className='page'> 
        <div className={blogStyle.blogPage}>
        {
            urlError | !pageText ? (
                <div className={blogStyle.error}>
                    <h2>This Blog Post does not exist</h2>
                    <p>Please browse other blog posts <Link to="/Blog">here</Link></p>
                </div>
            ) : (
                <>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <meta name="Description" content={pageText.MetaDescription}/>
                        <meta name="viewport" content="width=device-width, initial-scale=1"/>
                        <link rel="canonical" href={"https://www.danjbennett.com/blog/" +  pageText.LinkName } />
                        <title>Daniel Bennett - {pageText.Title}</title>
                    </Helmet>
                    <div className={blogStyle.head}>
                        <div>
                            <h1>{ pageText.Title }</h1>
                            <span className={blogStyle.date}>Published: { pageText.Date }</span>
                        </div>
                        <div className={blogStyle.share}>
                            <p>Share</p>
                        </div>
                    </div>
                    { ReactHtmlParser(pageText.Body) } 
                </>
            )
        }
        </div>
    </div>
  )
}