import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import headerStyle from './header.module.scss';

class Header extends Component {
  constructor() {
    super();
    this.state = {
        classes: headerStyle.nav
    }
  }

  clickHandler() {
      let lClasses = this.state.classes
      if (this.state.classes.includes(headerStyle.active)) {
          lClasses = lClasses.replace(headerStyle.active,"")
      } else {
          lClasses = lClasses + " " + headerStyle.active
      }
      this.setState({ classes: lClasses});
  }

  render() {
    return (
      <header>
        <div className="page">
            <div className={headerStyle.leftHeader}>
              <h1><a href="/">Daniel Bennett</a></h1>          
              <h2><a href="/">Web Developer</a></h2>
            </div>
            <span className={headerStyle.mobileButton} onClick={this.clickHandler.bind(this)}><FontAwesomeIcon icon={['fas', 'bars']}></FontAwesomeIcon></span>
            <ul className={this.state.classes}>  
                <li className={headerStyle.item1}><NavLink className={({ isActive }) => isActive ? headerStyle.isActive : ''} to="/">Home</NavLink></li>
                <li className={headerStyle.item2}><NavLink className={({ isActive }) => isActive ? headerStyle.isActive : ''} to="/Portfolio">Portfolio</NavLink></li>
                {/* <li className={headerStyle.item3}><NavLink className={({ isActive }) => isActive ? headerStyle.isActive : ''} to="/Blog">Blog</NavLink></li> */}
                <li className={headerStyle.item3}><NavLink className={({ isActive }) => isActive ? headerStyle.isActive : ''} to="/About">About</NavLink></li>
                <li className={headerStyle.item4}><NavLink className={({ isActive }) => isActive ? headerStyle.isActive : ''} to="/Contact">Contact</NavLink></li>
                <li />
            </ul>
        </div>
      </header>
    );
  }
}

export default Header;