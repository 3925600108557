import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import projectStyle from './portfolio.css'
import nicholasburmanStyle from './nicholasburman.css'

import desktop from '../../images/nicholasburman_desktop.png';
// import tablet from '../../images/nicholasburman_tablet.png';
import mobile from '../../images/nicholasburman_mobile.png';

class Nicholasburman extends Component {
  render() {
    return (
        <div>
          <Helmet>
              <meta charSet="utf-8"/>
              <meta name="Description" content="I'm Dan, I develop websites in various technologies in my spare time. Check out some of my projects and get in touch if you would like a website developed."/>
              <meta name="viewport" content="width=device-width, initial-scale=1"/>
              <link rel="canonical" href="https://www.danjbennett.com/Portfolio/Nicholasburman" />
              <title>Daniel Bennett - Nicholas Burman</title>
          </Helmet>
          <div className={projectStyle.slantContainer}>
            <div className={projectStyle.slant}>
              <div className="page">
                <h1 className={projectStyle.title}>Nicholas Burman</h1>
                <div className={projectStyle.technologies}>
                  <span className={nicholasburmanStyle.angular}><FontAwesomeIcon icon={['fab', 'angular']}></FontAwesomeIcon></span>
                  <span className={nicholasburmanStyle.html}><FontAwesomeIcon icon={['fab', 'html5']}></FontAwesomeIcon></span>
                  <span className={nicholasburmanStyle.css}><FontAwesomeIcon icon={['fab', 'css3']}></FontAwesomeIcon></span>
                  <span className={nicholasburmanStyle.css}><FontAwesomeIcon icon={['fab', 'sass']}></FontAwesomeIcon></span>
                </div>
                <p className={projectStyle.intro}>This website was a project I took over and now maintain for a student and writer. This website is written in Angular JS and uses a third party CMS for content management.</p>
                <Link className={projectStyle.button} aria-label="Visit Website - nicholascburman" rel="noreferrer" target="_blank" to="//nicholascburman.com/">Visit Website</Link>
              </div>
            </div>
          </div>
          <div className="pageOF">
            <div className={projectStyle.devices}>
            <div className={projectStyle.desktop}><img src={desktop} alt="desktop" /></div>
            {/* <div className={projectStyle.tablet}><img src={tablet} alt="tablet"/></div> */}
            <div className={projectStyle.mobile}><img src={mobile} alt="mobile"/></div> 
          </div>
        </div>
      </div>
    );
  }
}

export default Nicholasburman;