import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import cvStyle from './cv.css'

class cv extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <meta charSet="utf-8"/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <meta name="robots" content="noindex,nofollow"/>
          <link rel="canonical" href="https://www.danjbennett.com/CV" />
          <title>Daniel Bennett - CV</title>
        </Helmet>
        <p className={cvStyle.intro}>Software engineer with over ten years of experience in the computing industry, currently working at BAE Systems and creating commission-based freelance web development projects on the side. I have a keen interest in graphic design, programming and learning new web-based technologies.</p>
        <h2 className={cvStyle.center}>Technologies</h2>
        <ul className={cvStyle.technologies}>
            <li><FontAwesomeIcon icon={['fab', 'react']}></FontAwesomeIcon>React JS</li>
            <li><FontAwesomeIcon icon={['fab', 'js-square']}></FontAwesomeIcon>TypeScript</li>
            <li><FontAwesomeIcon icon={['fab', 'java']}></FontAwesomeIcon>Java</li>
            <li><FontAwesomeIcon icon={['fab', 'linux']}></FontAwesomeIcon>Bash</li>
            <li><FontAwesomeIcon icon={['fab', 'php']}></FontAwesomeIcon>PHP</li>
            <li><FontAwesomeIcon icon={['fab', 'html5']}></FontAwesomeIcon>HTML</li>
            <li><FontAwesomeIcon icon={['fab', 'sass']}></FontAwesomeIcon>SCSS</li>
            <li><FontAwesomeIcon icon={['fas', 'terminal']}></FontAwesomeIcon>Python</li>
            <li><FontAwesomeIcon icon={['fab', 'js-square']}></FontAwesomeIcon>jQuery</li>
            <li><FontAwesomeIcon icon={['fas', 'terminal']}></FontAwesomeIcon>Ansible</li>
            <li><FontAwesomeIcon icon={['fas', 'terminal']}></FontAwesomeIcon>Docker</li>
            <li><FontAwesomeIcon icon={['fab', 'node-js']}></FontAwesomeIcon>JavaScript</li>
            <li><FontAwesomeIcon icon={['fas', 'terminal']}></FontAwesomeIcon>PowerShell</li>
            <li><FontAwesomeIcon icon={['fas', 'database']}></FontAwesomeIcon>PL/SQL</li>
            <li><FontAwesomeIcon icon={['fab', 'aws']}></FontAwesomeIcon>AWS</li>
        </ul>
        {/* <span className={cvStyle.divider}></span> */}
        <h1 className={cvStyle.title}>Experience</h1>
        <h2 className={cvStyle.place}>Freelance Web Development</h2>
        <span className={cvStyle.period}>2015 - Present</span>
        <p>I am a freelance web developer, using the skills developed through my on-site learning on a range of commissioned projects. My portfolio currently contains five live websites, created and supported in my own time mainly using React JS, please look at my website for more details on these <Link aria-label="portfolio" to="/Portfolio">projects</Link>.</p>
        <p>These websites are all built using Jenkins and deployed to a Dockerised environment using Ansible. The websites are managed by the clients using the cockpit content management system.</p>
        <p>Currently I am working on two new projects that I am writing using Next.js and TypeScript. These will be deployed to a new server using CircleCI and the Strapi content management system.</p>
        <h4>Responsibilities</h4>
        <ul className={cvStyle.cvList}>
          <li>Creating and agreeing website designs with the client.</li>
          <li>Developing JavaScript front-end and back-end systems.</li>
          <li>Developing Deployment Pipelines (Jenkins and CircleCI).</li>
        	<li>Managing/supporting Linux based nginx web servers.</li>
          <li>Setting up content management systems (Cockpit and Strapi).</li>
          <li>Liaising with the client for requirements and supporting any issues.</li>
        </ul>
       {/* <span className={cvStyle.divider}></span> */}
        <h2 className={cvStyle.place}>BAE Systems Applied Intelligence</h2>
        <span className={cvStyle.period}>Jan 2017 – Nov 2024</span>
        <p>Developing and supporting critical applications, writing unit, integration and e2e tests, building deployment pipelines, creating and testing prototype software, writing support documentation - all following agile SAFe principles using the Atlassian suite.</p>
        <h3>Software Engineering</h3>
        <h4>Second Development Team</h4>
        <ul className={cvStyle.cvList}>
          <li>Development of a Typescript/ReactJS application with an Express.js API and unit, integration and e2e testing.</li>
          <li>Developing a set of C# streaming microservices with unit tests using Kafka and Docker in AWS.</li>
          <li>Feature work for an external customer’s Golang application.</li>
          <li>Mentoring and writing guides for new joiners.</li>
          <li>Creating support dashboards using Grafana and Kibana.</li>
          <li>Demoing our applications in sprint review sessions.</li>
          <li>Writing frontend JavaScript tests using Junit and Cypress.</li>
          <li>Deploying applications using various DevOps/Automation tools.</li>
          <li>Writing helm charts to deploy to OpenShift in an AWS environment.</li>
          <li>Being responsible for daily checks of systems in our wider team.</li>
          <li>Performance and Soak testing applications in both non and production environments.</li>
        </ul>
        <h4>First Development Team</h4>
        <ul className={cvStyle.cvList}>
          <li>Developing a Java enrichment microservice framework with Spring technology.</li>
          <li>Creating terraform scripts to deploy architecture to AWS and Azure cloud platforms.</li>
          <li>Creating and running performance tests on applications in Java and Python.</li>
          <li>Writing Python scripts to integrate with external team’s frameworks.</li>
          <li>Developing Java applications to work with oozie pipelines for a Hadoop platform.</li>
          <li>Developing pipelines in Bamboo/Jenkins and converting to bamboo specs, configuration as code, in Java and YAML.</li>
          <li>AWS developer training with QA.</li>
        </ul>
        <h3>DevOps Engineering</h3>
        <p>Supporting critical applications, fixing specific issues using a ticketing system. Development of scripts to help solve and automate issues. Creating a web-based dashboard for monitoring system health. Working out of hours support on call and critical deployments.</p>
        <h4>Responsibilities</h4>
        <ul className={cvStyle.cvList}>
            <li>Keeping data compliant with legal policies, managing data retention policies.</li>
            <li>Analysing large data sources with SQL and creating PSQL scripts.</li>
            <li>Windows Server support – services, scheduled tasks, failover clusters, DFSR (Distributed File System Replication), disk management.</li>
            <li>Functional testing for applications.</li>
            <li>Developing and maintaining a web-based dashboard over several environments.</li>
        </ul>
        <h2 className={cvStyle.place}>QA Training</h2>
        <span className={cvStyle.period}>June 2016 – Dec 2016</span>
        <p>Manchester based Six-month training course for my software engineering role at BAE Systems. Completed modules included: </p>
            <ul className={cvStyle.cvList}>
                <li>Java</li>
                <li>Java EE</li>
                <li>Cyber security</li>
                <li>SQL</li>
                <li>UX</li>
                <li>Linux</li>
                <li>Web Development Skills</li>
                <li>Software Testing </li>
            </ul>
        <p>Qualifications from the course listed below.</p>
        <h2 className={cvStyle.place}>Evosite</h2>
        <span className={cvStyle.period}>October 2014 – March 2016</span>
        <h3>Web Developer</h3>
        <p>Developed large-scale commercial PHP websites for a web development business. Projects included Flogas, Your Move and Deborah Meaden’s personal website.</p>
        <p>Each website was responsive and built around a look and functionality based on in-house designs. The websites also used an in-house built content management system used with each website, which I helped develop and test on.</p>
        <h1 className={cvStyle.title}>Education</h1>
        <ul className={cvStyle.education}>
            <li>AWS Certified Developer (2022) – Associate <FontAwesomeIcon icon={['fab', 'aws']}></FontAwesomeIcon></li>
            <li>QA Training (2016) qualifications:
                <ul>
                    <li>Oracle Certified Associate, Java SE 7 Programmer <FontAwesomeIcon icon={['fab', 'java']}></FontAwesomeIcon></li>
                    <li>ISTQB-BCS Certified Tester Foundation Level</li>
                    <li>ITIL Foundation certificate in IT Service Management</li>
                </ul>
            </li>
            <li>Bridgwater College (2012 - 2014) - HND Diploma Level 5 in Computing and System Development – Merit</li>
            <li>Bridgwater College (2010 - 2012) - BTEC Level 3 Extended Diploma in IT - Triple Distinction</li>
            <li>Haygrove Bridgwater (2005 -2010) - 9 GCSE’s Grade A – C</li>
        </ul>
      </div>
    );
  }
}

export default cv;
